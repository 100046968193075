import React from 'react'

import {makeStyles} from '@mui/styles'
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';


const styles = makeStyles({
  thankYouForBooking: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  thankYouTitle: {
    textAlign: 'center',
  },
  thankYouDescription: {
    textAlign: 'center',
  },
})

function ThanksYouPage({t, bookingId}) {
  const classes = styles();

  return (
    <>
      <div className={classes.thankYouForBooking}>
        <CheckCircleOutlineRoundedIcon style={{fontSize: '5em', color: 'green'}} />
        <h1 className={classes.thankYouTitle}>{t('tyBooking')}</h1>
        <p className={classes.thankYouDescription}>{t("contactText")}<br /><br />{t("yourId")}: #{bookingId}</p>
      </div>
    </>
  )
}

export default ThanksYouPage