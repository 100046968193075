import {Typography} from '@mui/material'
import {Box} from '@mui/system'
import React from 'react'
import {Colors} from '../styles'

function Footer() {
  return (
    <Box sx={styles.container}>
      <Typography variant="h5" gutterBottom>
        Yamens barbershop
      </Typography>
      <Box sx={styles.bottom}>
        <Typography style={styles.copyright} variant="body2">
          Yamen's barbershop © {new Date().getFullYear()}
        </Typography>
        <Typography style={styles.copyright} variant="body2">
          Skapad med ❤️ av <a style={styles.link} target="_blank" href="https://www.abodsakka.xyz">Abdulrahman Sakah</a>
        </Typography>
      </Box>
    </Box>
  )
}

const styles = {
  container: {
    backgroundColor: Colors.primary,
    width: '100%',
    height: '10%',
    padding: '1rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: Colors.snowWhite
  },
  copyright: {
    color: Colors.snowWhite,
  },
  link: {
    color: Colors.linkColor,
  },
  bottom: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  }
}

export default Footer