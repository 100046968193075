import {Box, Link} from '@mui/material'
import React from 'react'
import {Colors} from '../styles'
import Button from './Button'
import Cookies from 'universal-cookie';

function CookieConsent() {
  const [show, setShow] = React.useState(true);

  const setCookiesAccepted = () => { 
    const cookies = new Cookies();
    cookies.set("acceptedCookies", true, {path: "/"});
    setShow(false);
  }

  return (
    <>
      {show && (
        <Box sx={styles.container}>
          <h3>Vi värdesätter din integritet</h3>
          <p>
            Den här webbplatsen använder cookies för att ge dig en bättre användarupplevelse.<br />
            Läs mer om våra användarvillkor <Link to="/policy">här</Link>.
          </p>
          <Box style={styles.buttonsContainer}>
            <Button style={{marginRight: '1rem'}} onClick={() => setShow(false)} variant='outline' color='secondary'>
              Accepterar inte
            </Button>
            <Button onClick={() => setCookiesAccepted()} variant='contained' color='secondary'>
              Accepterar
            </Button>
          </Box>
        </Box>
      )}
    </>
  )
}

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: Colors.primary,
    color: Colors.snowWhite,
    padding: '1rem',
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '1rem',
    flexWrap: 'wrap',
  }
}

export default CookieConsent