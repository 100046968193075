import React from 'react'

import {makeStyles} from '@mui/styles'
import { Button } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import CheckIcon from '@mui/icons-material/Check';
const styles = makeStyles({
  bsItemTitle: {
    fontWeight: 500,
    fontSize: '1rem',
  },
  bookingSummery: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'flex-start',
  },
  bsInfo: {
    width: '100%',
  },
  bsItem: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    borderBottom: '1px dotted rgba(73, 106, 204, 1)',
  },
  bsItemDescription: {
    color: 'rgba(73, 106, 204, 1)',
  },
  bsMainService: {
    color: 'rgba(73, 106, 204, 1)',
    paddingBottom: '.5rem',
    fontSize: '1rem',
    fontWeight: 500,
    display: 'flex',
    flexDirection: 'row',
    marginTop: '1rem',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  bsServiceName: {
    margin: '0',
    marginRight: '1rem',
  },
  bsServicePrice: {
    margin: '0',
    fontWeight: 400,
  },
  bsAddon: {
    color: 'rgba(44, 66, 130, 1)',
    fontSize: '.8rem',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
  },
  bsServiceandAddons: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'flex-start',
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '50%',
    justifyContent: 'space-evenly',
  },
})

function BookingSummeryPage({t, firstName, lastName, parsedDate, selectedTimeSlot, selectedService, servicePrice, selectedAddons, totalPrice, goBackStep, createBooking, setStep, step}) {

  const classes = styles()

  return (
  <>
    <div className={classes.bookingSummery}>
      <h1 className={classes.bsTitle}>{t("bookingSummary")}</h1>
      <p className={classes.bsDescription}>{t("dear")} {firstName} {lastName}<br /> {t("bookingSumText")}:</p>
      <div className={classes.bsInfo}>
        <div className={classes.bsItem}>
          <h2 className={classes.bsItemTitle}>{t("dtForBooking")}:</h2>
          <p className={classes.bsItemDescription}>{parsedDate} {t("at")} {selectedTimeSlot}</p>
        </div>
        <div className={classes.bsItem}>
          <h2 className={classes.bsItemTitle}>{t("servicesBooked")}:</h2>
          <div className={classes.bsServiceandAddons}>
            <div className={classes.bsMainService} style={selectedAddons.length > 0 ? {borderBottom: '1px dotted rgba(73, 106, 204, 1)'} : {}}>
              <p className={classes.bsServiceName}>{selectedService}</p>
              <p className={classes.bsServicePrice}>{servicePrice} Kr</p>
            </div>
            {selectedAddons.map(addon => (
              <div className={classes.bsAddon} key={addon.id}>
                <p className={classes.bsAddonName}>{addon.name}</p>
                <p className={classes.bsAddonPrice}>{addon.price} Kr</p>
              </div>
            ))}
          </div>
        </div>
        <div className={classes.bsItem}>
          <h2 className={classes.bsItemTitle}>{t("totalPrice")}:</h2>
          <p className={classes.bsItemDescription}>{totalPrice} Kr</p>
        </div>
      </div>
      </div>
      <small style={{marginTop: '1rem'}}>Genom att utföra bokning så accepterar du vår <a href="/policy">användarpolicy</a></small>
    <div style={{justifyContent: 'space-around', width: '100%', marginTop: '1rem'}} class={classes.buttonsContainer}>
      <Button style={{borderRadius: '100%', padding: '1rem 0'}} className={classes.nextStepButton} onClick={goBackStep} variant="outlined"><ChevronLeftIcon /></Button>
      <Button style={{borderRadius: '100%', padding: '1rem 0'}} color="success" className={classes.nextStepButton} onClick={() => setStep(step + 1)} variant="outlined"><CheckIcon /></Button>
    </div>
  </>
  )
}

export default BookingSummeryPage