import React, {useEffect, useState} from 'react'
import {makeStyles} from '@mui/styles'
import { Typography, Popover, TextField, InputAdornment, CircularProgress, Button, Skeleton } from '@mui/material';
import axios from 'axios';

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

const styles = makeStyles({
  servicesContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    width: '100%',
    height: '70%',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      width: '0.4em'
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.5)',
      boxShadow: '0 0 1rem rgba(0,0,0,0.3)',
      borderRadius: '1rem',
    }
  },
  service: {
    width: '100%',
    borderBottom: '1px solid rgba(73, 106, 204, 1)',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '1rem',
    '&:hover': {
      cursor: 'pointer',
    }
  },
  serviceDescription: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'flex-start',
  },
  serviceName: {
    fontWeight: 'bold',
    margin: '0',
  },
  servicePrice: {
    fontSize: '1rem',
    fontWeight: 300,
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '50%',
    justifyContent: 'space-evenly',
  },
})

function AddonPage({apiURL, setError, isMobile, t, setSelectedAddons, selectedAddons, totalPrice, goNextStep, goBackStep, setTotalPrice, setTotalDuration, serviceDuration}) {

  const classes = styles()
  
  const [services, setServices] = useState([]);

  const [addons, setAddons] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchAddons = async () => {
    setIsLoading(true);
    let res = await axios.get(`${apiURL}/getAddons`);
    let addonsJson = res.data.data;
    setAddons(addonsJson);
    setIsLoading(false);
  }
  
const handleAddonSelect = (addonId, addonName, addonPrice, duration) => {
    // if object in list already, remove it
    if (selectedAddons.length > 0) {
      if (selectedAddons.filter(item => item.id === addonId).length > 0) {
        let newAddons = selectedAddons.filter(function (item) {
          return item.id !== addonId;
        })
        setSelectedAddons(newAddons);
        setTotalPrice(totalPrice - Number(addonPrice));
        setTotalDuration(Math.abs(Number(serviceDuration) - Number(duration)));
      } else {
        setSelectedAddons([...selectedAddons, {id: addonId, name: addonName, price: addonPrice}]);
        setTotalPrice(totalPrice + Number(addonPrice));
        setTotalDuration(Number(duration) + Number(serviceDuration));
      }
    } else {
      setSelectedAddons([{
        id: addonId,
        name: addonName,
        price: addonPrice
      }]);
      setTotalPrice(totalPrice + Number(addonPrice));
      setTotalDuration(Number(duration) + Number(serviceDuration));
    }
}
  
  useEffect(() => {
    fetchAddons();
  }, [])

  return (
  <>
      <div className={classes.servicesContainer}>
        {isLoading ? (
          <>
            {[...Array(5)].map((_, i) => (
              <Skeleton key={i} style={{marginBottom: '1rem'}} variant="rectangular" width="100%" height="30%" />
            ))}
          </>
        )
        :
          (
          <>
            {addons.map(addon => (
                <div className={classes.service} onClick={() => handleAddonSelect(addon.id, addon.name, addon.price, addon.duration)} key={addon.id}>
                  <div className={classes.serviceDescription}>
                    <h3 style={isMobile ? {fontSize: '1rem'} : {fontSize: '1.5rem'}} className={classes.serviceName}>{addon.name}</h3>
                    <h4 style={isMobile ? {fontSize: '.8rem'} : {fontSize: '1rem'}} className={classes.servicePrice}>{t("price")}: {addon.price}</h4>
                  </div>
                  <div className={classes.serviceCheckbox}>
                    {selectedAddons.filter(item => item.id === addon.id).length > 0 ? (
              
                      <CheckBoxIcon style={{
                        fontSize: '2rem',
                        color: 'rgba(73, 106, 204, 1)',
                      }} className={classes.serviceCheckboxIcon} />
                    )
                      :
                      (
                        <CheckBoxOutlineBlankIcon style={{
                          fontSize: '2rem',
                          color: 'rgba(73, 106, 204, 1)',
                        }} className={classes.serviceCheckboxIcon} />
                      )
                    }
            
                  </div>
                </div>
            ))}
          </>
        )}
    </div>
    <h1 style={isMobile ? {fontSize: '1rem'} : {fontSize: '2rem'}}>{t("total")}: {totalPrice}</h1>
    <div style={{justifyContent: 'space-between', width: '100%', marginTop: '1rem'}} class={classes.buttonsContainer}>
      <Button style={{borderRadius: '100%', padding: '1rem 0'}} className={classes.nextStepButton} onClick={goBackStep} variant="outlined"><ChevronLeftIcon /></Button>
      <Button style={{borderRadius: '100%', padding: '1rem 0'}} className={classes.nextStepButton} onClick={goNextStep} variant="outlined"><ChevronRightIcon /></Button>
    </div>
  </>
  )
}

export default AddonPage