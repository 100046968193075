import React, {useEffect} from 'react'
import {Colors} from '../styles'
import Footer from './Footer'
import Navbar from './Navbar'
import Cookies from 'universal-cookie';
import CookieConsent from './CookieConsent';

function Container({children, style}) {
  const cookies = new Cookies();

  return (
    <div style={{...styles.container, ...style}}>
      <Navbar />
      {children}
      {cookies.get("acceptedCookies") ? null : <CookieConsent />}
      <Footer />
    </div>
  )
}

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    backgroundColor: Colors.snowWhite,
  }
}

export default Container