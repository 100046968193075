import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import qouteBg from '../assets/images/qoute-bg.png';
import {Colors} from '../styles';
import {Rating} from '@mui/material';
const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const reviews = [
  {
    label: 'Abdullah Mahmoud',
    text: 'Underbart jobb. min favorit frisör',
    rating: 5,
  },
  {
    label: 'Bird',
    text:
      'Precis det jag har letat efter.',
    rating: 4.5
  },
];

function CustomerReviews() {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = reviews.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <Box sx={{ maxWidth: 400, flexGrow: 1 }}>
      <AutoPlaySwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {reviews.map((step, index) => (
          <div key={step.label}>
            <Box style={styles.reviewContainer}>
              <Box style={styles.review}>
                <Typography style={styles.reviewLabel}>
                  {step.text}
                </Typography>
                <Rating style={{
                  marginTop: '.5rem',
                  marginBottom: '.5rem',
                }} name="half-rating" defaultValue={step.rating} precision={0.5} readOnly />
                <Typography style={styles.reviewLabel}>
                  -{step.label}
                </Typography>
              </Box>
            </Box>
          </div>
        ))}
      </AutoPlaySwipeableViews>
      <MobileStepper
        style={{backgroundColor: Colors.snowWhite}}
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            {theme.direction === 'rtl' ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === 'rtl' ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
          </Button>
        }
      />
    </Box>
  );
}

const styles = {
  reviewContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '1.5rem',
  },
  review: {
    backgroundImage: `url(${qouteBg})`,
    backgroundColor: '#d5d9e0',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    width: '80%',
    padding: '1rem',
    borderRadius: '1rem',
    padding: '3rem',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
  },
}

export default CustomerReviews;