import React, {useState} from 'react'
import Container from '../components/Container'
import headerVideo from '../assets/videos/headerVideo.mp4'
import {Box, Typography} from '@mui/material'
import Button from '../components/Button'
import yamensImage from '../assets/images/yamen.png'
import CustomerReviews from '../components/CustomerReviews'

function Home() {

  const [isMobile, setIsMobile] = useState(false)

  React.useEffect(() => {
		if (window.innerWidth < 768) {
			setIsMobile(true);
		}
		document.title = "Yamen barbershop";
	}, []);

	return (
		<Container>
			<Box sx={styles.header}>
				<video style={styles.headerVideo} autoPlay loop muted playsInline>
					<source src={headerVideo} type='video/mp4' />
				</video>
			</Box>
			<Box
				sx={{
					...styles.aboutMobile,
					display: { xs: "flex", md: "none" }
				}}
			>
				<Typography
					style={{ marginBottom: "1rem", marginTop: "1rem" }}
					variant='h4'
				>
					Vem är vi?
				</Typography>
				<img
					src={yamensImage}
					style={{ ...styles.yamensImg, width: "60%" }}
					alt='Yamen bakeer'
				/>
				<Typography variant='body1'>
					Med flera års erfarenhet och passion för frisör yrket, har vi
					behärskat spelet genom att bli Valdemarsviks bästa frisör salong med
					kunder som kommer från hela sverige för att få den perfekta frisyren.
				</Typography>
				<Button
					onClick={() => (window.location.href = "/booking")}
					style={{ width: "50%", marginTop: "1.5rem" }}
				>
					Boka nu
				</Button>
			</Box>
			<Box
				sx={{
					...styles.aboutMd,
					display: { xs: "none", md: "flex" },
					width: "50%",
					marginTop: "2rem"
				}}
			>
				<img
					src={yamensImage}
					style={{
						...styles.yamensImg,
						width: "20%"
					}}
					alt='Yamen bakeer'
				/>
				<Box sx={styles.infoContainer}>
					<Typography
						style={{ marginBottom: "1rem", marginTop: "1rem" }}
						variant='h4'
					>
						Vem är vi?
					</Typography>
					<Typography style={{ width: "90%" }} variant='body1'>
						Med flera års erfarenhet och passion för frisör yrket, har vi
						behärskat spelet genom att bli Valdemarsviks bästa frisör salong med
						kunder som kommer från hela sverige för att få den perfekta
						frisyren.
					</Typography>
					<Button
						onClick={() => (window.location.href = "/booking")}
						style={{ width: "30%", marginTop: "1rem" }}
					>
						Boka nu
					</Button>
				</Box>
			</Box>

			<Box sx={styles.userReview}>
				<Typography
					style={{ marginBottom: "1rem", marginTop: "1rem" }}
					variant='h3'
				>
					kundrecensioner
				</Typography>
				<Typography
					style={{ marginBottom: "1rem", marginTop: "1rem" }}
					variant='h6'
				>
					Vad våra kunder säger om oss
				</Typography>
			</Box>
			<CustomerReviews />
			<Box sx={styles.findUs}>
				<Typography
					style={{ marginBottom: "1rem", marginTop: "1rem" }}
					variant='h3'
				>
					Hitta till oss
				</Typography>
				<iframe
					style={styles.map}
					width='500'
					height='1000'
					src='https://maps.google.com/maps?q=Yamen%20barbershop%20valdemarsvik&t=k&z=17&ie=UTF8&iwloc=&output=embed'
					frameborder='0'
					scrolling='no'
				></iframe>
			</Box>
		</Container>
	);
}

const styles = {
  headerVideo: {
    width: '100%',
    marginTop: '-4%',
    zIndex: '-1',
  },
  aboutMobile: {
    margin: '0 10% 0 10%',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  aboutMd: {

  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  yamensImg: {
    marginBottom: '1rem',
  },
  mapouter: {
    position: 'relative',
    textAlign: 'right',
    height: '50%',
    width: '100%',
  },
  gmap_canvas: {
    overflow: 'hidden',
    background: 'none !important',
    height: '100%',
    width: '100%',
  },
  findUs: {
    margin: '10% 0 10% 0',
  },
  map: {
    marginTop: '1rem',
    marginBottom: '1rem',
    width: '100%',
    height: '100%',
  },
  warning: {
    backgroundColor: '#a67135',
    color: 'white',
    padding: '1rem',
    marginBottom: '1rem',
    marginTop: '1rem',
    borderRadius: '.5rem',
    '@media (max-width: 768px)': {
      margin: '1% 2% 0 2%',
    }
  }
}

export default Home