import logo from './logo.svg';
import './App.css';
import Booking from './views/Booking';
import {Route, BrowserRouter, Routes, Switch, Router} from 'react-router-dom';
import Loginpage from './components/Admin/Loginpage';
import {t, setLanguage} from './locale/translate'
import Cookies from 'universal-cookie';
import axios from 'axios';
import {useEffect, useState} from 'react';
import * as Sentry from "@sentry/react";
import Home from './views/Home';
import AdminRoute from './navigation/AdminRoute';
import About from './views/About';
import Userpolicy from './views/Userpolicy';

function App() {
	const cookie = new Cookies();
	const CURRENT_VERSION = "1.0.0";
	const [updateAvailable, setUpdateAvailable] = useState(false);

	let apiURL = "https://api.abodsakka.xyz/api/v1/booker"; //PRODUCTION
	// let apiURL = "http://localhost:9000/api/v1/booker"; // DEVELOPMENT
	// hello world
	const checkForUpdate = async () => {
		let res = await axios.get(apiURL + `/checkForUpdate/${CURRENT_VERSION}`);
		let data = res.data;
		if (data.message !== CURRENT_VERSION) {
			setUpdateAvailable(true);
		}
	};

	useEffect(() => {
		checkForUpdate();
	}, []);

	return (
		<div className='App'>
			<BrowserRouter>
				<Routes>
					<Route path='/' element={<Home apiURL={apiURL} t={t} />} />
					<Route path='/booking' element={<Booking apiURL={apiURL} t={t} />} />
					<Route path='/about' element={<About t={t} />} />
					<Route path='/policy' element={<Userpolicy />} />
				</Routes>
			</BrowserRouter>
		</div>
	);
}

export default App;