import React, {useEffect, useState} from 'react'
import {makeStyles} from '@mui/styles'
import { Typography, Popover, TextField, InputAdornment, CircularProgress, Button, Skeleton } from '@mui/material';
import axios from 'axios';

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

const styles = makeStyles({
  servicesContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    width: '100%',
    height: '70%',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      width: '0.4em'
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.5)',
      boxShadow: '0 0 1rem rgba(0,0,0,0.3)',
      borderRadius: '1rem',
    }
  },
  service: {
    width: '100%',
    borderBottom: '1px solid rgba(73, 106, 204, 1)',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '1rem',
    '&:hover': {
      cursor: 'pointer',
    }
  },
  serviceDescription: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'flex-start',
  },
  serviceName: {
    fontWeight: 'bold',
    margin: '0',
  },
  servicePrice: {
    fontSize: '1rem',
    fontWeight: 300,
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'flex-end',
  },
})

function ServicesPage({apiURL, setError, isMobile, t, step, setStep, setSelectedService, selectedService, totalPrice, setServicePrice, setTotalPrice, setTotalDuration}) {

  const classes = styles()
  
  const [services, setServices] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  
  let amountSkeleton = 5;

  const fetchServices = async () => {
    setIsLoading(true);
    let res = await axios.get(`${apiURL}/getServices`);
    let servicesJson = res.data.data;
    setServices(servicesJson);
    setIsLoading(false);
  }


  const handleServiceSelect = (serviceId, servicePrice, duration) => {
    setSelectedService(serviceId);
    setServicePrice(servicePrice);
    setTotalPrice(Number(servicePrice));
    setTotalDuration(Number(duration));
  }

  const validateService = () => {
    if (selectedService) {
      setError(null);
      setStep(step + 1);
    } else {
      setError(t("errorPleaseSelectService"));
    }
  }

  useEffect(() => {
    fetchServices();
  }, [])

  return (
    <>
      <div className={classes.servicesContainer}>
        {isLoading ? (
          <>
            {[...Array(amountSkeleton)].map((_, i) => (
              <Skeleton key={i} style={{marginBottom: '1rem'}} variant="rectangular" width="100%" height="30%" />
            ))}
          </>
        ):
          (
          <>
            {services.map(service => (
              <div className={classes.service} onClick={() => handleServiceSelect(service.name, service.price, service.duration)} key={service.id}>
                <div className={classes.serviceDescription}>
                  <h3 style={isMobile ? {fontSize: '1rem'} : {fontSize: '1.5rem'}} className={classes.serviceName}>{service.name}</h3>
                  <h4 style={isMobile ? {fontSize: '.8rem'} : {fontSize: '1rem'}} className={classes.servicePrice}>{t("price")}: {service.price}</h4>
                </div>
                <div className={classes.serviceCheckbox}>
                  {selectedService !== service.name ? (
                    <CheckBoxOutlineBlankIcon style={{
                      fontSize: '2rem',
                      color: 'rgba(73, 106, 204, 1)',
                    }} className={classes.serviceCheckboxIcon} />
                  )
                    :
                    (
                      <CheckBoxIcon style={{
                        fontSize: '2rem',
                        color: 'rgba(73, 106, 204, 1)',
                      }} className={classes.serviceCheckboxIcon} />
                  )
                }
                  
                </div>
              </div>
            ))}
          </>
        )}
      </div>
      <h1 style={isMobile ? {fontSize: '1rem'} : {fontSize: '1.5rem'}}>{t("total")}: {totalPrice}</h1>
      <div style={{justifyContent: 'flex-end', width: '100%', marginTop: '1rem'}} class={classes.buttonsContainer}>
        <Button style={{borderRadius: '100%', padding: '1rem 0'}} className={classes.nextStepButton} onClick={validateService} variant="outlined"><ChevronRightIcon /></Button>
      </div>
  </>
  )
}

export default ServicesPage