import React, {useEffect} from 'react';
import {Box, Typography} from '@mui/material';

import Container from '../components/Container';

import bookingHeaderImage from '../assets/images/booking_header.jpg';
import {Colors} from '../styles';
import Booker from '../components/Booker';
import {t} from '../locale/translate';

function Booking({apiURL}) {

  useEffect(() => {
    document.title = 'Yamen barbershop — ' + t('nav_booking');
  }, [])
  
  return (
    <Container style={styles.container}>
      <Box style={styles.header}>
        <Typography variant="h4">{t("nav_booking")}</Typography>
      </Box>
      <Booker apiURL={apiURL} />
    </Container>
  );
}

const styles = { 
  header: {
    width: '100%',
    paddingTop: '15%',
    paddingBottom: '15%',
    background: `linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${bookingHeaderImage})`,
    marginTop: '-5%',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: Colors.snowWhite
  }
}

export default Booking;
