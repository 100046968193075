import {Typography, Box} from '@mui/material'
import React from 'react'
import {useEffect} from 'react';
import Container from '../components/Container'
import {t} from '../locale/translate';

function About() {
  useEffect(() => {
    document.title = 'Yamen barbershop — ' + t('nav_about');
  }, [])
  return (
		<Container>
			<Box style={{ padding: "1rem", height: "100%" }}>
				<Typography variant='h4' gutterBottom>
					Vem är vi
				</Typography>
				<Typography variant='body1' gutterBottom>
					Genom flera års erfarenhet och passion för frisör yrket, har behärskat
					spelet genom att bli Valdemarsviks bästa frisör salong med kunder som
					kommer från hela sverige för att få den perfekta frisyren.
				</Typography>
				<Typography variant='h4' gutterBottom>
					Kontakta oss
				</Typography>
				<Typography variant='body1' gutterBottom>
					Tel: <a href='tel:+460721209574'>072-120 95 74</a>
				</Typography>
				<Typography variant='body1' gutterBottom>
					Mail:{" "}
					<a href='mailto:kontakt@yamenbarbershop.com'>
						kontakt@yamenbarbershop.com
					</a>
				</Typography>

				<Typography variant='h4' gutterBottom>
					Hitta till oss
				</Typography>
				<Typography variant='body1' gutterBottom>
					Garvaregatan 6, 615 30 Valdemarsvik
				</Typography>
				<Box sx={styles.findUs}>
					<iframe
						style={styles.map}
						width='500'
						height='500'
						src='https://maps.google.com/maps?q=Yamen%20barbershop%20valdemarsvik&t=k&z=17&ie=UTF8&iwloc=&output=embed'
						frameborder='0'
						scrolling='no'
					></iframe>
				</Box>
			</Box>
		</Container>
	);
}

const styles = {
  map: {
    marginTop: '1rem',
    marginBottom: '1rem',
    width: '100%',
  }
}

export default About