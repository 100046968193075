import React from 'react';

import {Alert, Button, CircularProgress, InputAdornment, TextField} from '@mui/material'
import {makeStyles} from '@mui/styles'
import {useNavigate, Navigate} from 'react-router-dom'
import axios from 'axios';

import PersonIcon from '@mui/icons-material/Person';
import LockIcon from '@mui/icons-material/Lock';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';


const styles = makeStyles({
  loginContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loginWidget: {
    width: '50%',
    height: '60%',
    backgroundColor: '#fff',
    borderRadius: '1rem',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.5)',
  },
  loginImage: {
    width: '40%',
    height: '100%',
    backgroundImage: 'url(https://images.unsplash.com/photo-1618005198919-d3d4b5a92ead?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80)',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    borderRadius: '1rem 0 0 1rem',
  },
  loginForm: {
    width: '60%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  Title: {
    fontSize: '2rem',
    fontWeight: 400,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    width: '100%',
    height: '33%',
  },
  w60: {
    width: '60%',
  },
  roundedButton: {
    width: '10%',
    height: '11%',
    borderRadius: '50rem !important',
  }
})

function Loginpage({apiURL, t, cookie}) {
  
  const classes = styles();
  const navigate = useNavigate();

  const [disableBtn, setDisableBtn] = React.useState(true)
  const [username, setUsername] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [error, setError] = React.useState('')
  const [loading, setLoading] = React.useState(false)

  const handleUsername = (e) => {
    setUsername(e.target.value)
    if (e.target.value.length > 0 && password.length > 0) {
      setDisableBtn(false)
    } else {
      setDisableBtn(true)
    }
  }

  const handlePassword = (e) => {
    setPassword(e.target.value)
    if (e.target.value.length > 0 && username.length > 0) {
      setDisableBtn(false)
    } else {
      setDisableBtn(true)
    }
  }

  const handleSubmit = async () => { 
    setLoading(true)
    setDisableBtn(true)
    setError('')
    let data = {
      'username': username,
      'password': password
    }
    
    var formBody = [];

    for (var property in data) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    
    let Config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
        
      }
    }

    if (cookie.get("user")) {
      console.log("User already logged in")
      navigate('/admin/dashboard')
    }

    let res = await axios.post(`${apiURL}/login`, formBody, Config).catch(err => {
      setLoading(false)
      setDisableBtn(false)
      console.log("error: " + err.response.data.message)
      setError(err.response.data.message)
    })

    if (res) {
      let cookieData = {username: res.data.username, email: res.data.email}
      cookie.set('user', res.data.data, {path: '/'})
      console.log(res.data.data)
      navigate('/administrator/dashboard');  
    }

    setLoading(false)
    setDisableBtn(false)

  }

  return (
    <div className={classes.loginContainer}>
      {cookie.get("user") ? <Navigate to="/administrator/dashboard" /> : null}
      <div className={classes.loginWidget}>
        <div className={classes.loginImage} />
        <div className={classes.loginForm}>
          <h1 className={classes.Title}>Login to admin panel</h1>
          {error && <Alert className={classes.w60} severity="error">{error}</Alert>}
          <form className={classes.form}>
            <TextField
              className={classes.w60}
              label="Username"
              value={username}
              onChange={handleUsername}
              InputProps={{
              endAdornment: <InputAdornment position="start">
                <PersonIcon />
              </InputAdornment>
              }}
            />
            <TextField
              type="password"
              className={classes.w60}
              value={password}
              onChange={handlePassword}
              label="Password"
              InputProps={{
              endAdornment: <InputAdornment position="start">
                <LockIcon />
              </InputAdornment>
              }}
            />
          </form>
          <Button disabled={disableBtn} onClick={handleSubmit} className={classes.roundedButton} variant="outlined" color="success">
            {loading ? <CircularProgress size={24} /> : <DoneRoundedIcon />}
            
          </Button>
        </div>
      </div>
    </div>
  )
}

export default Loginpage