import React, {useEffect, useState} from 'react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

import axios from 'axios';
import {CircularProgress} from '@mui/material';

ChartJS.register(ArcElement, Tooltip, Legend);

function generateRandomColor(lablesArray) {
  let colors = []
    for (let i = 0; i < lablesArray.length; i++) {
      colors.push('#' + Math.floor(Math.random() * 16777215).toString(16))
    }
    return colors
}

function BookingAmount({t, cookie, apiURL}) {

  const [bookingServiceAmount, setBookingServiceAmount] = useState(null)
  const [bookingAmountLabels, setBookingAmountLabels] = useState(null)
  const [chartColors, setChartColors] = useState(null)
  const [data, setData] = useState(null)
  const fetchStats = async () => {
    let res = await axios.get(`${apiURL}/stats`)
    let amount = []
    let lables = []
    let tempData = {}
    Object.keys(res.data.data.bookingAmount[0]).forEach(key => {
      amount.push(res.data.data.bookingAmount[0][key].count)
      lables.push(res.data.data.bookingAmount[0][key].service)
    });

    tempData = {
      labels: lables,
      datasets: [
        {
          data: amount,
          backgroundColor: generateRandomColor(lables),
          label: 'Booking Amount',
          hoverOffset: 0
        }]
    }
    setData(tempData)

  }

  // console.log(data)  

  useEffect(() => {
    fetchStats()
  }, [])
  

  return (
    <>
      {data ? <Doughnut data={data} options={{
        responsive: true,
        aspectRatio: 1,
        height: 200,

      }} />
      : <CircularProgress />
    }
    </>
  )
}

export default BookingAmount