import React, {useEffect} from 'react'
import AdminNav from './AdminNav'
import { Chip, Typography } from '@mui/material'
import {DataGrid} from '@mui/x-data-grid';
import axios from 'axios';
import {Navigate} from 'react-router-dom'

function AdminBookings({t, apiURL, cookie, updateAvailable}) {

  const columns = [
    {
      field: 'id',
      headerName: t("bookingId"),
      width: 150,
      renderCell: (rowData) => {
        return <a href={`/administrator/bookings/${rowData.id}`}>{rowData.id}</a>
      }
    },  
    {
      field: 'customerName',
      flex: 1,
      headerName: t("customerName"),
      width: 150,
      editable: false,
    },
    {
      field: 'bookingDate',
      headerName: t("bookingDate"),
      flex: 1,
      width: 150,
      editable: false,
    },
    {
      field: 'bookingTime',
      headerName: t("bookingTime"),
      flex: 1,
      width: 110,
      editable: false,
    },
    {
      field: 'bookingStatus',
      headerName: t("bookingStatus"),
      flex: 1,
      width: 160,
      editable: false,
      renderCell: (params) => {
        let color = 'warning'
        let text = 'PENDING'
        if (params.value === 'PENDING') {
          text = t("statusPending")
          color = 'warning'
        } else if (params.value === 'ACCEPTED') {
          text = t("statusAccepted")
          color = 'success'
        } else if (params.value === 'REJECTED') {
          text = t("statusRejected")
          color = 'error'
        }

          
        return (
          <Chip
            label={text}
            color={color}
          />
        )
      }
    },
    {
      field: 'bookingService',
      headerName: t("bookedService"),
      flex: 1,
      width: 160,
      editable: false,
    },
    {
      field: 'bookingTotal',
      headerName: t("totalPrice"),
      flex: 1,
      width: 110,
      editable: false,
    },

  ];

  const [rows, setRows] = React.useState([]);
  const [selected, setSelected] = React.useState([]);

  const [loading, setLoading] = React.useState(true);

  const fetchBookings = async () => {
    setLoading(true);
    let res = await axios.get(apiURL + '/getAllBookings').catch(err => {
      console.log(err.message);
    });

    let bookings = res.data.data;
    let bookingRows = [];

    Object.keys(bookings).forEach(key => {
      bookingRows.push({
        id: bookings[key].id,
        customerName: bookings[key].person_name,
        bookingDate: bookings[key].date,
        bookingTime: bookings[key].time,
        bookingStatus: bookings[key].status,
        bookingService: bookings[key].service,
        bookingTotal: bookings[key].total_price,
      });
    });
    setRows(bookingRows);
    setLoading(false);
  }

  const handleRowSelect = (selection) => {
    setSelected(selection);
  }

  useEffect(() => {
    fetchBookings();
  }, []);

  return (
    <div className="adminContainer">
      {!cookie.get("user") && <Navigate to="/administrator/" />}
      <div>
        <AdminNav updateAvailable={updateAvailable} t={t} />
      </div>
      <div className="p-1">
      <Typography variant="h4" component="h2" gutterBottom>
          {t('amBookings')}
        </Typography>
        <div style={{ height: 400, width: '100%' }}>
          <DataGrid
            style={{
              backgroundColor: '#fff',
              boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
              overflowX: 'scroll',
              }}
            rows={rows}
            columns={columns}
            loading={loading}
            pageSize={5}
            onSelectionModelChange={handleRowSelect}
            rowsPerPageOptions={[5]}
            checkboxSelection
            disableSelectionOnClick
          />
        </div>
      </div>
    </div>
  )
}

export default AdminBookings