import {Box} from '@mui/system'
import React from 'react'
import {Colors} from '../styles'

function Button({
  children,
  onClick = () => { },
  variant = 'contained',
  color = 'primary',
  style = {},
}) {
  return (
    <Box
      sx={{
        ...styles.container,
        ...styles[`${variant}_${color}`],
        ...style,
        '&:hover': styles[`${variant}_${color}_hover`],
        '&:active': styles[`${variant}_${color}_active`],
      }}
      onClick={onClick}
    >
      {children}
    </Box>
  )
}

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1rem',
    borderRadius: '1rem',
    width: 'auto',
    transition: 'all .3s ease-in-out',
    '-webkit-touch-callout': 'none',
  },
  contained_primary: {
    backgroundColor: Colors.primary,
    color: Colors.snowWhite,
  },
  contained_primary_hover: {
    backgroundColor: Colors.secoundery,
    cursor: 'pointer',
  },
  contained_primary_active: {
    backgroundColor: Colors.active,
  },
  outline_primary: {
    border: `.15rem solid ${Colors.primary}`,
    color: Colors.primary,
  },
  outline_primary_hover: {
    backgroundColor: Colors.primary20,
    cursor: 'pointer',
  },
  outline_primary_active: {
    backgroundColor: Colors.primary50,
  },
  text_primary: {
    color: Colors.primary,
  },
  text_primary_hover: {
    backgroundColor: Colors.primary20,
    cursor: 'pointer',
  },
  text_primary_active: {
    backgroundColor: Colors.primary50,
  },
  contained_secondary: {
    backgroundColor: Colors.snowWhite,
    color: Colors.primary,
  },
  contained_secondary_hover: {
    backgroundColor: Colors.snowWhite20,
    color: Colors.snowWhite,
    cursor: 'pointer',
  },
  contained_secondary_active: {
    backgroundColor: Colors.snowWhite50,
  },
  outline_secondary: {
    border: `.15rem solid ${Colors.snowWhite}`,
    color: Colors.snowWhite,
  },
  outline_secondary_hover: {
    backgroundColor: Colors.snowWhite20,
    cursor: 'pointer',
  },
  outline_secondary_active: {
    backgroundColor: Colors.snowWhite50,
  },
  text_secondary: {
    color: Colors.snowWhite,
  },
  text_secondary_hover: {
    backgroundColor: Colors.snowWhite20,
    cursor: 'pointer',
  }
}

export default Button