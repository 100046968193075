import {
  AppBar,
  Container,
  IconButton,
  Toolbar,
  Typography,
  Menu,
  MenuItem,
  Button,
  Tooltip,
  Avatar,
} from '@mui/material'
import {Box} from '@mui/system'
import React, {useEffect} from 'react'
import MenuIcon from '@mui/icons-material/Menu';
import {Colors} from '../styles';
import CloseIcon from '@mui/icons-material/Close';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import {setLanguage, t} from '../locale/translate'
import {Link} from 'react-router-dom';
const navItems = [
  {
    name: t("nav_home"),
    href: '/'
  },
  {
    name: t("nav_booking"),
    href: '/booking'
  },
  {
    name: t("nav_about"),
    href: '/about'
  },
]
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

function Navbar() {

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [navMenuOpen, setNavMenuOpen] = React.useState(false);

  const handleOpenNavMenu = () => {
    setNavMenuOpen(!navMenuOpen);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    console.log(navMenuOpen);
    if (navMenuOpen) {
      setNavMenuOpen(false);
    }
  };


  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };


  return (
  <>
  <AppBar className='navbar' style={styles.appBar} position="static">
      <Container style={{zIndex: '100'}} maxWidth="lg">
        <Toolbar disableGutters>
        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
              >
              {navMenuOpen ? <CloseIcon style={styles.menuButton} /> : <MenuIcon style={styles.menuButton} />}
            </IconButton>
          </Box>
          <Typography
            variant="h5"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: 'flex',
              flexGrow: 1,
              fontSize: '1rem',
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: '#000',
              textDecoration: 'none',
            }}
          >
            Yamen's Barbershop
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {navItems.map((item, index) => (
              <Button
                key={index}
                component={Link}
                to={item.href}
                sx={{ my: 2, color: '#000', display: 'block' }}
              >
                {item.name} 
              </Button>
            ))}
          </Box>
        </Toolbar>
      </Container>
      </AppBar>
      <Box
        style={{
          position: 'fixed',
          top: 0,
          left: navMenuOpen ? '0' : '-100%',
          width: '100%',
          height: '100%',
          zIndex: '2',
          // transform: navMenuOpen ? 'translateX(0)' : 'translateX(-100%)',          
          transition: 'all .3s ease-in-out',
        }}
      >
        <Box
          sx={{
            ...styles.navMenu,
          }}
        >
          {navItems.map((item, index) => (
            <Typography component={Link} to={item.href} style={styles.navItemText} key={index}>
              {item.name}
            </Typography>
          ))}
          <Box id='navbar' style={styles.socialIcons}>
            <a href="https://www.facebook.com/yamen.barbershop/" target="_blank">
              <FacebookIcon style={styles.socialIcon} />
            </a>
            <a href="https://www.instagram.com/yamenbarbershop/" target="_blank">
              <InstagramIcon style={styles.socialIcon} />
            </a>
            <a href="tel:+460732111108">
              <LocalPhoneIcon style={styles.socialIcon} />
            </a>
          </Box>
        </Box>
      </Box>
  </>
  )
}

const styles = {
  appBar: {
    backgroundColor: '#fff',
    marginBottom: '1rem',
    zIndex: '100'
  },
  menuButton: {
    color: '#000',
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '50%',
  },
  logo: {
    width: '5%',
    height: '5%',
    marginRight: '1rem',
  },
  navMenu: {
    // position: 'absolute',
    // top: '0',
    // left: '0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '70%',
    height: '100%',
    // zIndex: '100',
    backgroundColor: Colors.primary,
    color: Colors.snowWhite,
  },
  navItemText: {
    fontSize: '1.5rem',
    marginBottom: '1.5rem',
    color: Colors.snowWhite,
    textDecoration: 'none',
  },
  socialIcons: {
    width: '60%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '2rem',
  },
  socialIcon: {
    fontSize: '1.5rem',
    color: Colors.snowWhite,
    textDecoration: 'none',
  }
}

export default Navbar