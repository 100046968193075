import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@mui/material'
import React, {useEffect} from 'react'
import Container from '../components/Container'

function Userpolicy() {


  useEffect(() => {
    document.title = 'Yamen barbershop — User policy';
  }, [])
  
  return (
    <Container>
      <h1>Använderpolisy och säkerhet</h1>
      <p>Här kan du läsa lite om hur vi hanterar dina personuppgifter och vad du har rätt för.</p>

      <h2>Vad är personuppgifter?</h2>
      <p>Personuppgifter är information som är nödvändig för att du ska kunna använda vår tjänst.
        Personuppgifter kan vara namn, adress, e-postadress, telefonnummer, etc.</p>
      
      <h2>Varför använder vi dina personuppgifter?</h2>
      <p>Våra tjänster använder personuppgifter för att kunna b.la. kommunicera med dig, ge dig information om våra tjänster, och göra din navigering i vår tjänst bättre och snabbare.</p>

      <h2>Vad gör vi med dina personuppgifter?</h2>
      <p>Dina personuppgifter lagras med samband med din bokning/köp på vår e-tjänst och det kommer att lagras där i 6 månader som därefter tasbort automatiskt. Du kan också be oss att radera dina person uppgifter från vår database genom att skriva till oss på <a href="mailto:kontakt@yamenbarbershop.com">kontakt@yamenbarbershop.com</a>.</p>

      <h2>Cookies/kakor, vad är de?</h2>
      <p>Cookies är en textfil som lagras i din webbläsare. De lagras på din dator och kan användas för att identifiera dig när du besöker vår webbplats. Dem används för att göra din upplevelse bättre och att kunna använda våra tjänster.</p>

      <h2>Vad gör vi med cookies?</h2>
      <p>Vi lagrar bl.a. din information lokalt på din dator, mobil, eller surf-platta så att nästa gång du ska göra en bokning eller ett köp så finns dina uppgifter där och så att du inte ska behöva fylla i dem varje gång.</p>

      <h2>Vilka kakor är det som lagras?</h2>

      <TableContainer style={{
        width: '95%',
        marginBottom: '2rem',
      }} component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Namn</TableCell>
              <TableCell>Beskrivning</TableCell>
              <TableCell>Innehåll</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          <TableRow>
              <TableCell>acceptedCookies</TableCell>
              <TableCell>Denna indikerar om du har accepterat att lagra kakor på din dator. Kakor lagras endast om du tillåter det</TableCell>
              <TableCell>Sant, om du har accepterat</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>customerInfo</TableCell>
              <TableCell>Din information som vi har samlat under din bokning/köp. Den används så att nästa gång du gör ett köp så slipper du filla in denna information</TableCell>
              <TableCell>Ditt Första namn, efternamn, e-postadress, telefonnummer.</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  )
}

export default Userpolicy