import {makeStyles} from '@mui/styles'
import React, {useState, useEffect} from 'react'
import { Typography, Popover, TextField, InputAdornment, CircularProgress, Button } from '@mui/material';
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import DateFnsAdapter from '@date-io/date-fns';
import axios from 'axios';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import svLocale from 'date-fns/locale/sv';
import enLocale from 'date-fns/locale/en-GB';
import arLocale from 'date-fns/locale/ar';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';


const styles = makeStyles({
  LoadingOverLay: {
    position: 'absolute',
    width: '10%',
    height: '30%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  timesContainer: {
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'scroll',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    flexWrap: 'no-wrap',
  },
  timeSlot: {
    backgroundColor: 'rgba(0,0,0,.0)',
    borderBottom: '2px solid rgba(73, 106, 204, 1)',
    // borderRadius: '0.5em',
    marginTop: '1rem',
    padding: '.5rem .5rem .5rem .5rem',
    margin: '.2rem',
    border: 'none',
    transition: 'all 0.2s ease-in-out',
    '&:hover': {
      backgroundColor: 'rgba(144, 150, 166,.5)',
      borderRadius: '0.5em',
      cursor: 'pointer',
    },
  },
  timePopover: {
    height: '50%',
    borderRadius: '0.5em',
    '&::-webkit-scrollbar': {
      width: '0.4em'
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.5)',
      boxShadow: '0 0 1rem rgba(0,0,0,0.3)',
      borderRadius: '1rem',
    }
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    margin: '2rem 0 2rem 0',
    flexWrap: 'wrap',
    height: '70%',
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '50%',
    justifyContent: 'space-evenly',
  },
})

function TimeSelectionStep({apiURL, setError, selectedDate, setSelectedDate, setSelectedTimeSlot, isMobile, selectedTimeSlot, setParsedDate, t, setStep, step, duration, goBackStep}) {

  const classes = styles();

  const [timeSlots, setTimeSlots] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isDateOpen, setIsDateOpen] = useState(false);
  const [isTimeOpen, setIsTimeOpen] = useState(false);
  const [timesLoading, setTimesLoading] = useState(false);
  const [disabledDates, setDisabledDates] = useState([]);
  const [timeIsValid, setTimeIsValid] = useState(false);
  const [dateNotValid, setDateNotValid] = useState(false);
  const [disableNext, setDisableNext] = useState(true);
  const [loading, setLoading] = useState(false);

  const localeMap = {
    'sv': svLocale,
    'en': enLocale,
    'ar': arLocale,
  }

  const generateTimeSlots = async (date) => {
    setTimesLoading(true);
    let dateToGenerate = `${date.year}-${date.month}-${date.day}`;
    let currentTime = new Date();
    let currentTimeString = `${currentTime.getHours()}:${currentTime.getMinutes()}`;

    let res = await axios.get(`${apiURL}/generateTimeSlots?date=${dateToGenerate}&currentTime=${currentTimeString}&duration=${duration}`);
    let timeSlots = res.data.data;
    setTimeSlots(timeSlots);
    setTimesLoading(false);

    if (res.data.data.length === 0) {
      let month = date.month < 10 ? `0${date.month}` : date.month;
      let day = date.day < 10 ? `0${date.day}` : date.day;
      setDisabledDates([...disabledDates, `${date.year}-${month}-${day}`]);
    }
  }


  const handleSelectingTime = async (e) => {
    let year = selectedDate.getFullYear();
    let month = selectedDate.getMonth()+1;
    let day = selectedDate.getDate();

    setParsedDate(`${year}-${month}-${day}`);
  
    setSelectedTimeSlot(e.target.innerText);
    
    if (checkIfDateIsValid() && await checkTimeIsValid(e.target.innerText)) {
      setTimeIsValid(true);
      setDisableNext(false);
    } else {
      setTimeIsValid(false);
      setDisableNext(true);
    }
  }

  const fetchDisabledDates = async () => { 
    try {
      let result = await axios.get(`${apiURL}/getDisabledDates`).catch(err => {
        setError(err.message);
      });

      let dates = result.data.data;
      setDisabledDates(dates);
    }catch(err) {
      setError(err.message);
    }
  };


  const validateDateTime = () => {
    if (selectedDate && selectedTimeSlot) {
      setError(null);
      setStep(step + 1);
    } else {
      setError(t("errorPleaseSelectDateTime"));
    }
  }

  const handleDisabledDates = (date) => {
    if (date.getDay() === 0) {
      return true;
    }

    let month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
    let day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
    let dateString = `${date.getFullYear()}-${month}-${day}`;
    for (let i = 0; i < disabledDates.length; i++) {
      if (disabledDates[i] === dateString) {
        return true;
      }
    }
  }


  const handleDateChange = async (date) => {
    generateTimeSlots({
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate()
    });
    
    setSelectedDate(date);
    setSelectedTimeSlot(null);
    setDisableNext(true);
  }

  const handleTimePopoverClick = (e) => {
    setAnchorEl(e.currentTarget);
    setIsTimeOpen(true);
  }

  const checkIfDateIsValid = () => { 
    for (let i = 0; i < disabledDates.length; i++) {
      if (disabledDates[i] === `${selectedDate.getFullYear()}-${selectedDate.getMonth() + 1}-${selectedDate.getDate()}`) {
        return false;
      } else {
        return true;
      }
    }
  }

  const checkTimeIsValid = async (time) => { 
    setLoading(true);
    const timeValid = await axios.get(`${apiURL}/checkTime?date=${selectedDate.getFullYear()}-${selectedDate.getMonth() + 1}-${selectedDate.getDate()}&time=${time}`)

    setLoading(false);
    return timeValid.data.data;
  }

  const TimeSlots = ({slots}) => (
    <div className={classes.timesContainer} style={isMobile ? {} : {padding: '0 .4rem'}}>
      {timesLoading && <LoadingOverLay />}
        {slots.map(slot => (
          <button className={classes.timeSlot} key={slot} style={selectedTimeSlot === slot ? { backgroundColor: 'rgba(73, 106, 204,.2)', borderRadius: '.5rem'} : {}} onClick={handleSelectingTime}>{slot}</button>
        ))} 
    </div>
  )

  const LoadingOverLay = () => (
    <div className={classes.LoadingOverLay}>
      <CircularProgress />
    </div>
  )

  useEffect(() => {
    fetchDisabledDates();
    let date = new Date();
    generateTimeSlots({
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate()
    });

    setDateNotValid(checkIfDateIsValid());
  }, []);


  return (
    <>
      <div className={classes.flexRow}>
        <LocalizationProvider
          dateAdapter={DateFnsAdapter}
          locale={localeMap['sv']}
        >
          <DatePicker
            label={`${t("selectDate")} *`}
            shouldDisableDate={handleDisabledDates}
            value={selectedDate}
            onChange={handleDateChange}
            renderInput={(params) => <TextField style={isMobile ? {width: '100%'} : {}} {...params} />}
            minDate={new Date()}
          />
        </LocalizationProvider>

        <TextField
          style={isMobile ? {marginTop: '1rem'} : {marginLeft: '1rem'}}
          id="input-with-icon-textfield"
          label={t("selectTime")}
          className={classes.timeSelectionButton}
          value={selectedTimeSlot || ''}
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          onClick={handleTimePopoverClick}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <AccessTimeIcon />
              </InputAdornment>
            ),
          }}
          required
        />
      <Popover
        open={isTimeOpen}
        id="dateButton"
        onClose={() => setIsTimeOpen(false)}
        anchorEl={anchorEl}
        className={classes.timePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
          <div className={classes.timeSlotsBox}>
            <TimeSlots slots={timeSlots} />
          </div>
      </Popover>
      </div>
      <div style={{justifyContent: 'space-between', width: '100%', marginTop: '1rem'}} class={classes.buttonsContainer}>
        <Button style={{borderRadius: '100%', padding: '1rem 0'}} className={classes.nextStepButton} onClick={goBackStep} variant="outlined"><ChevronLeftIcon /></Button>
        <Button style={{borderRadius: '100%', padding: '1rem 0'}} className={classes.nextStepButton} onClick={validateDateTime} variant="outlined"><ChevronRightIcon /></Button>
      </div>
    </>
  )
}

export default TimeSelectionStep