import React from 'react'
import {Toolbar, IconButton, Typography, AppBar, Drawer, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Alert, AlertTitle} from '@mui/material';
import {styled, useTheme} from '@mui/material/styles';
import {makeStyles} from '@mui/styles';
import {Link} from 'react-router-dom';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import EventAvailableRoundedIcon from '@mui/icons-material/EventAvailableRounded';
import RoomServiceRoundedIcon from '@mui/icons-material/RoomServiceRounded';
import AddTaskIcon from '@mui/icons-material/AddTask';

const styles = {
  adminContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  notificationAlert: {
    justifySelf: 'center',
    alignSelf: 'center',
    width: '90%',
    marginTop: '1rem',
    borderRadius: '1rem !important',
  },
}

function AdminNav({t, updateAvailable}) {

  const [drawerOpen, setDrawerOpen] = React.useState(false);
  
  const theme = useTheme();

  const handleDrawerTrigger = () => {
    setDrawerOpen(!drawerOpen);
  };

  const menuItem = [
    {
      index: 0,
      name: t("amDashboard"),
      icon: () => <DashboardRoundedIcon />,
      path: "/administrator/dashboard"
    },
    {
      index: 1,
      name: t("amBookings"),
      icon: () => <EventAvailableRoundedIcon />,
      path: "/administrator/bookings"
    },
    {
      index: 2,
      name: t("amServices"),
      icon: () => <RoomServiceRoundedIcon />,
      path: "/administrator/services"
    },
    {
      index: 3,
      name: t("amAddons"),
      icon: () => <AddTaskIcon />,
      path: "/administrator/addons"
    },
  ]

  return (
    <div style={styles.adminContainer}>

      <TopBar position="fixed" open={drawerOpen}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerTrigger}
            edge="start"
            sx={{ mr: 2, ...(drawerOpen && { display: 'none' }) }}
          >
            <MenuRoundedIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            ⏱️ Booking system
          </Typography>
        </Toolbar>
      </TopBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={drawerOpen}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerTrigger}>
            {theme.direction === 'ltr' ? <ChevronLeftRoundedIcon /> : <ChevronRightRoundedIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {menuItem.map(item => (
            <ListItem as={Link} to={item.path} button key={item.index}>
              <ListItemButton>
                <ListItemIcon>{item.icon()}</ListItemIcon>
                <ListItemText style={{color: "#000"}} primary={item.name} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>
      {updateAvailable && (
        <Alert style={styles.notificationAlert} severity="warning">
          <strong>New update available</strong> — Click here to install new update
        </Alert>
      )}
    </div>
  )
}

const drawerWidth = 240;

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const TopBar = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  position: 'static',
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    marginBottom: 'rem',
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export default AdminNav