export const Colors = {
  primary: '#06131a',
  primary50: 'rgba(6, 19, 26, 0.5)',
  primary20: 'rgba(6, 19, 26, 0.2)',
  secoundery: '#183a4d',
  active: '#3d6d87',
  snowWhite: '#d2d3d4',
  snowWhite50: 'rgba(210, 211, 212, 0.5)',
  snowWhite20: 'rgba(210, 211, 212, 0.2)',
  linkColor: '#a7b5cc'
}