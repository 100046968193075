import React, { useEffect, useState } from 'react'
import {makeStyles} from '@mui/styles';
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import axios from 'axios';

import {CircularProgress, Step, Stepper, StepLabel, Button, TextField, Alert, MobileStepper, Grid, Typography} from '@mui/material';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import CheckIcon from '@mui/icons-material/Check';
import TimeSelectionStep from './BookingPage/TimeSelectionStep';
import ServicesPage from './BookingPage/ServicesPage';

import AddonPage from './BookingPage/AddonPage';
import CustomerInfoPage from './BookingPage/CustomerInfoPage';
import BookingSummeryPage from './BookingPage/BookingSummeryPage';
import ThanksYouPage from './BookingPage/ThanksYouPage';
import {t} from '../locale/translate';
import {Box} from '@mui/system';
import Cookies from 'universal-cookie';


function Booker({apiURL}) {

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [parsedDate, setParsedDate] = useState(null);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
  const [step, setStep] = useState(0);

  const [servicePrice, setServicePrice] = useState(0);
  const [selectedService, setSelectedService] = useState(null);

  const [selectedAddons, setSelectedAddons] = useState([]);

  const [error, setError] = useState(null);

  const [totalPrice, setTotalPrice] = useState(0);
  const [totalDuration, setTotalDuration] = useState(0);


  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [bookingId, setBookingId] = useState(null);

  const [isMobile, setIsMobile] = useState(windowWidth < 600);

  const steps = [t("moSelectService"), t("amAddons"), t("moSelectTime"), t("moCustomerInformation"), t("moConfirm"), t("moDone")];

  const cookies = new Cookies();
  
  const createBooking = async () => {
    setIsLoading(true);
    let data = {
      'userFirstName': firstName,
      'userLastName': lastName,
      'userEmail': email,
      'userPhoneNumber': phone,
      'bookingDate': `${selectedDate.getFullYear()}-${selectedDate.getMonth()+1}-${selectedDate.getDate()}`,
      'bookingTime': selectedTimeSlot,
      'bookingDuration': totalDuration,
      'service': selectedService,
      'servicePrice': servicePrice,
      'totalPrice': totalPrice,
      'shopID': 1,
    }

    
    if (selectedAddons.length > 0) {
      data['addonsJson'] = JSON.stringify({addons: selectedAddons});
    }
    
    var formBody = [];

    for (var property in data) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    
    let Config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
        
      }
    }

    let res = await axios.post(`${apiURL}/createBooking`, formBody, Config).catch(err => {
      setError(err.message);
      setIsLoading(false);
    });

    if (res.status === 200) {
      setBookingId(res.data.data.bookingId);
      setStep(step + 1);
    } else {
      setError(res.data.message);
    }
    setIsLoading(false);
  }

  const getUserInfo = () => {
    if(cookies.get("customerInfo")) {
      let customerInfo = cookies.get("customerInfo");
      setFirstName(customerInfo.firstName);
      setLastName(customerInfo.lastName);
      setEmail(customerInfo.email);
      setPhone(customerInfo.phone);
    }
  }

  const handleWindowResize = () => {
    if(window.innerWidth < 600) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
    setWindowWidth(window.innerWidth);
  }

  const goNextStep = () => {
    if(step < steps.length - 1) {
      setStep(step + 1);
    }
  }

  const goBackStep = () => {
    if (step > 0) {
      setStep(step - 1);
    }
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    getUserInfo();
  }, []);


  const GlobalLoadingOverlay = () => {

    return (

      <div style={styles.globalLoadingOverlay}>
        <CircularProgress />
      </div>

    )

  }

  return (
    <Box sx={styles.container}>
    <div style={styles.bookingContainer}>
        {isLoading && <GlobalLoadingOverlay />}

        {isMobile ? (
          <MobileStepper
          variant="progress"
          steps={steps.length}
          position="static"
          activeStep={step}
          sx={{width: '100%', height: '10%', alignSelf: 'center'}}
        />
       )
          :
          (
            <Stepper activeStep={step} style={{width: '100%', height: '10%', alignSelf: 'flex-start'}}>
          {steps.map((label, index) => (
            <Step key={index}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>

      )}
        
      <div style={styles.mainContainer}>
        {error !== null && (
          <Alert severity="error" style={isMobile ? {width: '100%', marginBottom: '1rem'}:{width: '50%', marginBottom: '1rem'}}>{error}</Alert>
        )}

          {step === 0 && (
            <ServicesPage
            apiURL={apiURL}
            setError={setError}
            isMobile={isMobile}
            t={t}
            step={step}
            setStep={setStep}
            setSelectedService={setSelectedService}
            selectedService={selectedService}
            totalPrice={totalPrice}
            setTotalDuration={setTotalDuration}
            setServicePrice={setServicePrice}
            setTotalPrice={setTotalPrice}
          />
        )}
        {step === 1 && (
          <AddonPage 
              apiURL={apiURL}
              setError={setError}
              isMobile={isMobile}
              t={t}
              step={step}
              setStep={setStep}
              setSelectedAddons={setSelectedAddons}
              selectedAddons={selectedAddons}
              totalPrice={totalPrice}
              serviceDuration={totalDuration}
              setTotalDuration={setTotalDuration}
              setTotalPrice={setTotalPrice}
              goBackStep={goBackStep}
              goNextStep={goNextStep}
              
          />
        )}
        {step === 2 && (
          <TimeSelectionStep apiURL={apiURL}
              setError={setError}
              selectedTimeSlot={selectedTimeSlot}
              setSelectedTimeSlot={setSelectedTimeSlot}
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              isMobile={isMobile}
              setParsedDate={setParsedDate}
              t={t}
              step={step}
              setStep={setStep}
              duration={totalDuration}
              goNextStep={goNextStep}
              goBackStep={goBackStep}
          />
        )}
        {step === 3 && (
          <CustomerInfoPage
            t={t}
            firstName={firstName}
            setFirstName={setFirstName}
            lastName={lastName}
            setLastName={setLastName}
            email={email}
            setEmail={setEmail}
            phone={phone}
            setPhone={setPhone}
            goBackStep={goBackStep}
            step={step}
            setStep={setStep}
            setError={setError}
            createBooking={createBooking}
          />
        )}
        {step === 4 && (
          <BookingSummeryPage
            t={t}
            firstName={firstName}
            lastName={lastName}
            parsedDate={parsedDate}
            selectedTimeSlot={selectedTimeSlot}
            selectedService={selectedService}
            servicePrice={servicePrice}
            selectedAddons={selectedAddons}
            totalPrice={totalPrice}
            goBackStep={goBackStep}
              createBooking={createBooking}
              setStep={setStep}
              step={step}
          />
        )}
        {step === 5 && (
          <ThanksYouPage 
            t={t}
            bookingId={bookingId}
          />
        )}
      </div>
    </div>
  </Box>
  )
}

const styles = {
  container: {
    width: '90%',
    height: '90vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    margin: 'auto',
  },
  bookingContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    width: '70%',
    marginTop: '1rem',
    marginBottom: '1rem',
    padding: '.5rem 2rem',
    borderRadius: '1rem',
    boxShadow: '0 0 1rem rgba(0,0,0,0.3)',
    backgroundColor: '#fff',
    height: '90%',
  },
  mainContainer: {
    width: '100%',
    height: '90%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#fff',
  },
  globalLoadingOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0,0,0,0.5)',
    zIndex: '9999',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}

export default Booker