import React from 'react'
import {makeStyles} from '@mui/styles';

import Cookies from 'universal-cookie';

import { Typography, Popover, TextField, InputAdornment, CircularProgress, Button,Grid } from '@mui/material';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

const styles = makeStyles({
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '50%',
    justifyContent: 'space-evenly',
  },
  customerInfoField: {
    '::webkit-outer-spin-button': {
      display: 'none',
    },
    '::-webkit-inner-spin-button': {
      display: 'none',
    },
  }
})

function CustomerInfoPage({t, firstName, setFirstName, lastName, setLastName, email, setEmail, phone, setPhone, goBackStep, step, setStep, setError, createBooking}) {
  
  const classes = styles();
  const cookies = new Cookies();
  
  const validateUserInformation = () => {
    let emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    let phoneRegex = /^\d{10}$/;

    if (firstName && lastName && email && phone) {
      if (emailRegex.test(email) && phoneRegex.test(phone)) {

        if (cookies.get("acceptedCookies")) { 
          cookies.set("customerInfo", {firstName, lastName, email, phone}, {path: '/'});
        }

        createBooking();

        setError(null);
        setStep(step + 1);
        
      }else {
        setError(t("errorInfoInvalid"));
      }
    } else {
      setError(t("errorMissingInfor"));
    }
  }

  return (
    <>
              
              <div className={classes.customerInfoContainer}>
                <Grid style={{marginBottom: '1rem'}} container spacing={3}>
                  <Grid item xs={12} sm={6}>
                      <TextField style={{width: '100%'}} className={classes.customerInfoField} variant="outlined" label={t("firstName")} value={firstName} onChange={e => setFirstName(e.target.value)} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                      <TextField style={{width: '100%'}} className={classes.customerInfoField} variant="outlined" label={t("lastName")} value={lastName} onChange={e => setLastName(e.target.value)} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                      <TextField style={{width: '100%'}} className={classes.customerInfoField} type='email' variant="outlined" label={t("email")} value={email} onChange={e => setEmail(e.target.value)} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField style={{width: '100%'}} className={classes.customerInfoField} type='number' variant="outlined" label={t("phoneNumber")} value={phone} onChange={e => setPhone(e.target.value)} />
                  </Grid>
                </Grid>
              </div>


              <div style={{justifyContent: 'space-around', width: '100%', marginTop: '1rem'}} class={classes.buttonsContainer}>
                <Button style={{borderRadius: '100%', padding: '1rem 0'}} className={classes.nextStepButton} onClick={goBackStep} variant="outlined"><ChevronLeftIcon /></Button>
                <Button style={{borderRadius: '100%', padding: '1rem 0'}} className={classes.nextStepButton} onClick={validateUserInformation} variant="outlined"><ChevronRightIcon /></Button>
              </div>
            </>

  )
}

export default CustomerInfoPage